:root {
   --vh: 100%;
   --pointColor01: #79926B;
   --pointColor02: #505B40;
   --pointColor03: #ECEAD0;
   --pointColor04: #F6F1EC;
   --pointColor05: #2C631B;
   --pointColor06: #f2f0eb;
   --subColor01: #202020;
   --subColor02: #484848;
   --subColor03: #707070;
   --subColor04: #AEAEAE;
   --subColor05: #E4E4E4;
   --subColor06: #FCFCFC;
   --subColor07: #f5f5f5;
   --subColor08: #d8d8d8;
   --subColor09: #f06000;
   --subColor10: #fbfbfe;
   --subColor11: #fbfbff;
   --subColor12: #f8f8f8;
}
/* html,
body {
    height: 100vh;
    height: var(--vh);
} */

* {
   color: inherit;
}

.hide {
   position: absolute;
   left: -9999px;
}
.only-mobile{
   display: none
}
.container {
   max-width: 1440px;
   margin-left: auto;
   margin-right: auto;
}

.montserrat {
   font-family: "Montserrat", sans-serif;
   font-optical-sizing: auto;
   font-weight: 600;
   font-style: normal;
}

.swiper-button-disabled {
   opacity: 0.2;
}
.noitem{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 150px;
}

/*  */
@media screen and (max-width:1500px) {
   .container {
      padding-left: 16px;
      padding-right: 16px;
   }
}

/* tablet, mobile */
@media screen and (max-width:1279px) {
   #root .pc-only {
      display: none
   }
}

@media screen and (max-width:767px){
   .only-mobile{
      display: block
   }
   .top-nav{
      flex-direction: column;
   }
   .top-nav .text_count{
      order: 1;
      width: 100%;
   }
   .top-nav .search_from{
      order: 0;
      width: 100%;
   }
   #root .top-nav .search_from .search_box{
      width: 100%;
      font-size: 14px;
      padding: 0px 16px;
   }
}